import {filterEmptyParams} from "~/utils/filters";

export function useCategories() {
  const categories = useState('categories', () => [])

  async function fetchCategories(companyId = null, choiceCategory = null) {
    return useHttpGet('categories', {
      query: filterEmptyParams({
        company_id: companyId,
        choice_category: choiceCategory
      })
    })
      .then((response) => {
        categories.value = response.data.value ?? []
      })
  }

    async function getCategoryBySlug(slug) {
    return useHttpGet('categories/slug/' + slug)
  }

    async function getCategoryById(id) {
    return useHttpGet('categories/' + id)
  }

  return {
    categories,
    fetchCategories,
    getCategoryBySlug,
    getCategoryById
  }
}

